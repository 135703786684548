<template>
  <div>
    <el-card>
      <!-- 查询区域 -->
      <el-form ref="form" :inline="true">
        <el-form-item label="分类">
          <el-select style="width:150px" v-model="queryParams.cate_id" clearable placeholder="请选择分类">
              <el-option
                v-for="(item,index) in courseTypeData"
                :key="index"
                :label="item.title"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程名">
          <el-input
           style="width:150px"
            placeholder="请输入课程名"
            v-model="queryParams.goods_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
           style="width:150px"
            placeholder="请输入备注"
            v-model="queryParams.remark"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input
           style="width:150px"
            placeholder="请输入手机号"
            v-model="queryParams.phone"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item label="支付状态">
           <el-select style="width:150px" v-model="queryParams.order_status" clearable placeholder="请选择分类">
              <el-option
                v-for="(item,index) in orderStatusArr"
                :key="index"
                :label="item.value"
                :value="item.key">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            style="width:300px"
            v-model="times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit"  icon="el-icon-search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toExport">导出</el-button>
        </el-form-item>

        <el-form-item style="float:right">
          <el-button type="primary" @click="toAddOrder">生成订单</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格区域 -->
      <el-table :data="orderList" class="orderTable" stripe style="width: 100%" border>
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <!-- <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column> -->
        <el-table-column prop="goods_name" label="课程名称" align="center">
        </el-table-column>
        <el-table-column prop="user_name" label="学员名称" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="电话" align="center">
        </el-table-column>
        <el-table-column prop="pay_price" label="价格" align="center">
        </el-table-column>

        <el-table-column prop="order_status" label="支付状态" align="center">
          <template slot-scope="scope">
             <el-tag
              type="success"
              v-if="scope.row.order_status == '已付款'"
              @click="editOrderEnable(scope.row.id,4)"
            >
              {{scope.row.order_status}}
            </el-tag>
            <el-tag type="danger" v-else @click="editOrderEnable(scope.row.id,2)"
              >{{scope.row.order_status}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="unlock_mode" label="解锁模式" align="center">
          <template slot-scope="scope" >
              <div style="color:red;">
                {{scope.row.unlock_mode}}
              </div>
          </template>
        </el-table-column>

        <el-table-column prop="rbac_name" label="老师" align="center"></el-table-column>

        <el-table-column prop="remark" label="备注" align="center">
            <template slot-scope="scope">
              <div :class="remarkID!==scope.row.id?'remark':''" @mouseenter="remarkEnter(scope.row)" @mouseleave="remarkLeave"
              style=" cursor: pointer;">
                {{scope.row.remark}}
              </div>
            </template>
        </el-table-column>
        <el-table-column label="下单时间" align="center">
          <template slot-scope="scope">
            {{
              $moment(scope.row.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" v-slot="scope" width="255">
          <el-button type="info" size="small" icon="el-icon-setting" @click="getOrderChapter(scope.row.id)" circle></el-button>
          <el-button type="danger" size="small" icon="el-icon-delete" circle @click="removeOrder(scope.row.id)"></el-button>
          <el-button type="success" size="small" icon="el-icon-info" @click="toViewDetails(scope.row)" circle></el-button>
          <el-button type="success" size="small" icon="el-icon-tickets" @click="addForeigners(scope.row)" circle></el-button>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[10, 20, 50]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>
    </el-card>

    <!-- 外国人订单签约 -->
    <el-dialog
        title="护照签约"
        :center="true"
        :visible.sync="addForeignersDialog"
        width="30%"
        @close="addForeignersClosed"
      >
        <el-form :model="editOrderForm">
          <el-row>
            <el-col :span="4">
              <span style="height:50px;line-height:50px">姓名:</span>
            </el-col>
            <el-col :span="20">
              <el-input
                style="height:50px;line-height:50px"
                placeholder="请输入姓名"
                v-model="client"
              ></el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <span style="height:50px;line-height:50px">护照ID:</span>
            </el-col>
            <el-col :span="20">
              <el-input
                style="height:50px;line-height:50px"
                placeholder="请输入护照ID"
                v-model="id_card"
              ></el-input>
            </el-col>
          </el-row>
          <el-row v-if="goods_type == 2">
            <el-col :span="4">
              <span style="height:50px;line-height:50px">联系方式:</span>
            </el-col>
            <el-col :span="20">
              <el-input
                style="height:50px;line-height:50px"
                placeholder="请输入联系方式"
                v-model="phone"
              ></el-input>
            </el-col>
          </el-row>
          <div class="footer-btn">
            <span slot="footer" class="dialog-footer" style="margin-top: 20px;">
            <!-- <el-button @click="eddDialog.editVisible = false">取 消</el-button> -->
            <el-button type="primary" @click="confirmSave()">确 定</el-button>
            </span>
          </div>
        </el-form>
    </el-dialog>

    <!-- 修改订单备注 -->
    <el-dialog
        title="修改备注"
        :center="true"
        :visible.sync="editOrderDialog"
        width="30%"
      >
        <el-form :model="editOrderForm">
          <el-form-item label="备注:" label-width="100px">
             <el-input
              type="textarea"
              :rows="4"
              v-model="editOrderForm.remark"
              placeholder="请输入备注"
            />
          </el-form-item>
          <!-- <el-form-item label="阶段" :label-width="formLabelWidth">
            <el-select v-model="editOrderForm.remark" placeholder="请选择" clearable v-if="editOrderForm.mold == 'japanese' || editOrderForm.mold == 'korean'">
              <el-option
                :label="item.id"
                :value="item.title"
                v-for="item in stageList1"
                :key="item.id"
              ></el-option>
            </el-select>
            <el-select v-model="editOrderForm.remark" placeholder="请选择" clearable v-else>
              <el-option
                :label="item.id"
                :value="item.title"
                v-for="item in stageList2"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label-width="40%">
            <el-button type="primary" @click="editOrderNow">立即修改</el-button>
          </el-form-item>
        </el-form>
    </el-dialog>

     <!-- 章节设置 -->
    <el-dialog
          width="40%"
          top="3%"
          title="订单章节设置"
          :visible.sync="orderSectionDialog"
          append-to-body
          :close-on-click-modal=false
          @close="setClosed">
          <div class="jurisdiction">
            <div class="unlock_mode">
              <div class="unlock_mode_title">解锁模式选择</div>
              <div>
                <el-radio-group v-model="modeRadio" size="small" @change="radioChang">
                    <el-radio :label="item.key" border v-for="item in unlockMode" :key="item.key">{{item.value}}</el-radio>
                 </el-radio-group>
              </div>
            </div>

            <div class="unlock_mode" v-if="modeRadio!==1">
              <el-form ref="form" :inline="true">

                <el-form-item label="章节开始">
                  <el-date-picker
                    v-model="startTime"
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>

                <el-form-item label="章节结束">
                  <el-date-picker
                    v-model="endTime"
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="saveChapterTime">保存</el-button>
                </el-form-item>
              </el-form>

            </div>
            <div class="jurisdiction-content">
              <div class="title_mode">自定义模式</div>
              <!-- 非系统章节模式 -->
              <el-tree
                :data="userChapterList"
                show-checkbox
                node-key="chapter_id"
                :props="defaultProps"
                :default-checked-keys="chapter_ids"
                :default-expand-all="true"
                ref="tree"
                v-if="modeRadio!==3">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span class="node_label">{{ node.label }}</span>
                    <span class="unlock_time">
                      {{
                        data.unlock_time?'解锁时间：'+$moment(data.unlock_time * 1000).format("YYYY-MM-DD HH:mm:ss"):''
                      }}
                    </span>
                  </span>
              </el-tree>
              <!-- 系统章节模式 -->
              <el-tree
                :data="userChapterList"
                show-checkbox
                node-key="chapter_id"
                :props="defaultProps"
                :default-checked-keys="chapter_ids"
                :default-expand-all="false"
                :expand-on-click-node="false"
                :auto-expand-parent="false"
                ref="tree"
                class="systemchapter"
                v-else>
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span class="node_label">{{ node.label }}</span>
                    <span class="unlock_time">
                      {{
                        data.unlock_time?'解锁时间：'+$moment(data.unlock_time * 1000).format("YYYY-MM-DD HH:mm:ss"):''
                      }}
                    </span>
                  </span>
              </el-tree>
            </div>
            <div class="jurisdiction-confirm">
               <el-button type="primary" @click="confirmPermissions">确定</el-button>
               <el-button type="primary" @click="orderSectionDialog=false">取消</el-button>
            </div>
          </div>
    </el-dialog>

    <!-- 详情弹框 -->
    <el-dialog title="详情" :visible.sync="detailsDialog" width="50%">
      <div class="details_box">
        <div class="details_info">
          <div class="details_title">
            <span>订单号：{{detailsData.order_sn}}</span>
            <span class="look_contract" @click="sel_contract(detailsData.order_sn)">查看合同</span>
             <!-- <span>课程名：{{detailsData.goods.goods_name}}</span> -->
          </div>

          <div class="details_content">
            <div class="details_content_left">
              <img :src="detailsData.goods.thumb.avthumb" alt="">
            </div>
            <div class="details_content_right">
               <div class="item_info">
               <span>课程名：{{detailsData.goods.goods_name}}</span>
              </div>
              <div class="item_info">
                <span>用户名：{{detailsData.user.nickname}}</span>
                <span>手机号：{{detailsData.user.area_code}} {{detailsData.user.phone}}</span>
                <span>ID：{{detailsData.user.id}}</span>
              </div>
              <div class="item_info">
                <span>价格：{{detailsData.price}}</span>
                <span>付款时间：{{$moment(detailsData.pay_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}</span>
                <span>付款方式：{{detailsData.pay_type}}</span>
              </div>
              <div class="item_info">
                <span>备注：{{detailsData.remark}} </span>
                <div class="edit_btn" @click="editOrder(detailsData)">编辑</div>
              </div>
            </div>
          </div>
        </div>


        <div class="learning_process_box">
          <div class="learning_process_title">学习进度</div>
          <div class="learning_process_table">
            <!-- 表格区域 -->
            <el-table :data="orderRateLearnList" class="orderTable" stripe style="width: 100%" border>

              <el-table-column prop="goodsLib.lib_name" label="章节名" align="center">
              </el-table-column>
              <el-table-column prop="goodsLibChapter.chapter_name" label="小节名" align="center">
              </el-table-column>

              <el-table-column prop="finish_learn" label="学习情况" align="center">
                <template slot-scope="scope">
                   <el-tag
                    type="success"
                    v-if="scope.row.finish_learn == 1"
                  >
                    已学习
                  </el-tag>
                  <el-tag type="danger" v-else-if="scope.row.finish_learn == 0 && scope.row.progress != 0"
                    >未学完
                  </el-tag>
                  <el-tag type="danger" v-else-if="scope.row.finish_learn == 0 && scope.row.progress == 0"
                    >未学习
                  </el-tag>
                </template>
              </el-table-column>

              <el-table-column label="提交时间" align="center">
                <!-- <template slot-scope="scope">
                  {{
                    scope.row.task_time!==0?$moment(scope.row.task_time * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ):''
                  }}
                </template> -->
                <template slot-scope="scope">
                  {{
                    scope.row.learn_time!==0?$moment(scope.row.learn_time * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ):''
                  }}
                </template>
              </el-table-column>

              <el-table-column  label="作业情况" align="center" v-slot="scope">
                  <!-- <div v-if="scope.row.finish_task" @click="editOrderUndoTask($event,scope.row)">
                    <el-checkbox  :value="scope.row.finish_task">已交</el-checkbox>
                  </div> -->
                  <div v-if="scope.row.finish_task == 1">已交</div>
                  <div v-else>未交</div>
              </el-table-column>

            </el-table>
          </div>
        </div>

      </div>
    </el-dialog>


    <!-- 生成订单弹框 -->
    <el-dialog title="生成订单" :visible.sync="orderDialog" :center=true width="40%" @close="addOrderClosed" :close-on-click-modal=false>
      <el-form :model="orderForm" ref="orderForm">
        <el-form-item label="课程" :label-width="formLabelWidth">
          <el-select style="width:60%"  v-model="orderForm.goods_id" filterable  placeholder="请选择课程">
            <el-option :label=item.goods_name :value=item.id v-for="item in CourseSelection" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户" :label-width="formLabelWidth">
          <el-select style="width:60%" filterable remote  clearable :remote-method="searchUser" v-loadmore="loadMore" v-model="orderForm.user_id"  placeholder="请选择用户">
            <el-option :label="`${item.phone}——${item.nickname}`" :value=item.id v-for="item in userArr" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="价格" :label-width="formLabelWidth">
            <el-input type="number" v-model.number="orderForm.price" placeholder="请输入价格"></el-input>
        </el-form-item>
        <el-form-item label="分类" :label-width="formLabelWidth">
          <el-select v-model="orderForm.mold" placeholder="请选择" clearable>
            <el-option
              :label="item.id"
              :value="item.title"
              v-for="item in moldList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="解锁模式" :label-width="formLabelWidth">
            <el-radio-group v-model="orderForm.unlock_mode" size="small">
              <el-radio :label="item.key" border v-for="item in unlockMode" :key="item.key">{{item.value}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="阶段" :label-width="formLabelWidth" v-if="orderForm.mold">
          <el-select v-model="orderForm.remark" placeholder="请选择" clearable v-if="orderForm.mold == 'japanese' || orderForm.mold == 'korean'">
            <el-option
              :label="item.id"
              :value="item.title"
              v-for="item in stageList1"
              :key="item.id"
            ></el-option>
          </el-select>
          <el-select v-model="orderForm.remark" placeholder="请选择" clearable v-else>
            <el-option
              :label="item.id"
              :value="item.title"
              v-for="item in stageList2"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item el-form-item label="备注" :label-width="formLabelWidth">
            <el-input
              type="textarea"
              :rows="4"
              v-model="orderForm.remark"
              placeholder="请输入备注（建议使用“姓名+阶段”模式）"
            />
        </el-form-item>
        <el-form-item label-width=40%>
           <el-button type="primary" :icon="isLoading?'el-icon-loading':''" @click="addOrderNow">{{isLoading?'生成中':'立即生成'}}</el-button>
        </el-form-item>

        <el-form-item v-if="orderHistory.length!==0" el-form-item label="历史订单" :label-width="formLabelWidth">
          <div class="order_history" v-for="(item,index) in orderHistory" :key="index">
              {{item.value}}
          </div>
        </el-form-item>

      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrder,
  removeOrder,
  getContract,
  OrderEnable,
  OrderChapter,
  OrderChapterSave,
  OrderSwitchMode,
  OrderSetUnlock,
  ExportOrder,
  OrderEdit,
  OrderAdd,
  SelectUser,
  UserIdOrder,
  OrderDetails,
  OrderRateLearn,
  OrderUndoTask,
  foreignersOrder,
} from "@/api/order";
import { selectCourseCate } from "@/api/courseApi";
import { getCourseSelection } from "@/api/api";

import FileSaver from "file-saver";
import XLSX from "xlsx";
export default {
  data() {
    return {
      orderList: [],
      queryParams: {
        page: 1,
        limit: 10,
        order_sn: "",
        order_status: null,
        remark: "",
        start_time: 0,
        end_time: 0,
        phone: "",
        cate_id: "",
        goods_name: "",
      },
      total: 0,
      times: null,
      detailsData: {
        goods: {
          thumb: {},
        },
        user: {},
      }, //详情数据
      detailsDialog: false, //详情弹框

      orderSectionDialog: false,
      addForeignersDialog: false,
      userChapterList: [],
      chapter_ids: [],
      unlockMode: [
        { key: 0, value: "系统" },
        { key: 1, value: "自定义" },
        { key: 2, value: "系统+自定义" },
        { key: 3, value: "章节+系统" },
      ],
      modeRadio: null,
      choiceRadio: null,
      unlock_mode: null,
      defaultProps: {
        children: "chapter",
        label: "lib_name",
      },
      order_id: null,

      startTime: "",
      endTime: "",
      courseTypeData: [],
      editOrderDialog: false,
      editOrderForm: {},
      remarkID: null,

      CourseSelection: [], //课程列表
      userArr: [],
      userTotal: 0,
      orderDialog: false,
      orderForm: {
        user_id: "",
        price: "",
        remark: "",
        goods_id: "",
        unlock_mode: 0,
        mold: "",
      },
      formLabelWidth: "100px",
      orderHistory: [],
      isLoading: false,
      orderStatusArr: [
        { key: 1, value: "待付款" },
        { key: 2, value: "已付款" },
        { key: 3, value: "已关闭" },
        { key: 4, value: "已退款" },
      ],
      orderRateLearnList: [],
      orderDetailsId: null,
      client: "",
      id_card: "",
      phone: "",
      goods_type: 1,
      // user_id: 0,
      moldList: [
        { id: "日语", title: "japanese" },
        { id: "法语", title: "french" },
        { id: "韩语", title: "korean" },
        { id: "西语", title: "spanish" },
        { id: "德语", title: "german" },
      ],
      stageList1: [
        { id: "N1", title: "N1" },
        { id: "N2", title: "N2" },
        { id: "N3", title: "N3" },
        { id: "N4", title: "N4" },
        { id: "N5", title: "N5" },
        { id: "一对一", title: "一对一" },
      ],
      stageList2: [
        { id: "A1", title: "A1" },
        { id: "A2", title: "A2" },
        { id: "B1", title: "B1" },
        { id: "B2", title: "B2" },
        { id: "C1", title: "C1" },
        { id: "C2", title: "C2" },
        { id: "一对一", title: "一对一" },
      ]
    };
  },
  watch: {
    "orderForm.user_id": {
      handler(newVal) {
        if (newVal == "") {
          this.orderHistory = [];
          let data = {
            page: 1,
            limit: 10,
          };
          SelectUser(data).then((res) => {
            this.userTotal = res.data.total;
            this.userArr = res.data.data;
          });
          return;
        }
        this.getUserIdOrder(newVal);
      },
      deep: true,
    },
    "orderForm.mold": {
      handler(newVal,oldVal) {
        if(newVal && oldVal){
          this.orderForm.remark = "";
        }
      },
      deep: true,
    }
  },
  created() {
    this.getCourseSelectionData();
    this.getSelectCourseCate();
    this.getOrderList();
    this.getUserListData();
  },

  methods: {
    // 获取订单详情
    getOrderDetails(id) {
      OrderDetails({ id }).then((res) => {
        this.detailsData = res.data;
      });
    },
    // 获取订单学习进度
    getOrderRateLearn(id) {
      OrderRateLearn({ id }).then((res) => {
        if (res.code == 1) {
          res.data.forEach((item) => {
            if (item.finish_task == 1) {
              item.finish_task = true;
            } else {
              item.finish_task = false;
            }
          });
          this.orderRateLearnList = res.data;
          this.$forceUpdate();
        }
      });
    },
    // 订单撤销作业
    async editOrderUndoTask(e, item) {
      if (e.target.tagName === "INPUT") return;
      if (item.finish_task === false) {
        this.$message.error("该学员还未提交作业不能撤销作业~");
        return;
      }
      const result = await this.$confirm(
        `是否撤销${this.detailsData.user.nickname} 的作业打卡?`,
        "撤销提示",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消撤销");
      let data = {
        user_id: item.user_id,
        chapter_id: item.chapter_id,
      };
      OrderUndoTask(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success(res.msg);
        this.getOrderRateLearn(this.orderDetailsId);
      });
    },
    // 根据用户ID获取订单列表
    getUserIdOrder(id) {
      UserIdOrder({ user_id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.orderHistory = res.data;
      });
    },
    // 获取用户列表
    getUserListData() {
      let data = {
        page: Math.ceil(this.userArr.length / 10) + 1,
        limit: 10,
      };
      SelectUser(data).then((res) => {
        this.userTotal = res.data.total;
        this.userArr = res.data.data;
      });
    },
    // 选择用户触底
    loadMore() {
      let data = {
        page: Math.ceil(this.userArr.length / 10) + 1,
        limit: 10,
      };
      SelectUser(data).then((res) => {
        if (this.userTotal < Math.ceil(this.userArr.length / 10) + 1) {
          return;
        }
        this.userArr = this.userArr.concat(res.data.data);
      });
    },
    // 用户搜索
    searchUser(query) {
      let data = {
        page: 1,
        limit: 10,
        phone: query,
      };
      SelectUser(data).then((res) => {
        this.userArr = res.data.data;
      });
    },
    // 获取课程列表
    getCourseSelectionData() {
      getCourseSelection().then((res) => {
        this.CourseSelection = res.data;
      });
    },
    // 点击生成订单
    toAddOrder() {
      this.orderDialog = true;
    },
    // 点击立即生成订单
    addOrderNow() {
      if (this.orderForm.goods_id == "") {
        this.$message.error("请选择课程");
        return;
      } else if (this.orderForm.user_id == "") {
        this.$message.error("请选择用户");
        return;
      } else if (this.orderForm.price == "" || this.orderForm.price < 0) {
        this.$message.error("请输入价格且不小于0");
        return;
      } else if (this.orderForm.mold == "") {
        this.$message.error("请选择分类");
        return;
      } else if (this.orderForm.remark == "") {
        this.$message.error("请输入阶段");
        return;
      } else if (this.isLoading == true) {
        return;
      }
      this.isLoading = true;
      OrderAdd(this.orderForm).then((res) => {
        this.isLoading = false;
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("生成订单成功");
        this.orderDialog = false;
        this.getOrderList();
      });
    },
    // 添加订单关闭事件
    addOrderClosed() {
      this.orderForm = {
        user_id: "",
        price: "",
        remark: "",
        goods_id: "",
        unlock_mode: 0,
        mold: "",
      };
    },
    // 鼠标进入备注
    remarkEnter(item) {
      this.remarkID = item.id;
    },
    // 鼠标离开备注
    remarkLeave() {
      this.remarkID = null;
    },
    // 修改订单
    editOrder(item) {
      this.editOrderForm = JSON.parse(JSON.stringify(item));
      this.editOrderDialog = true;
    },

    // 立即修改订单
    editOrderNow() {
      OrderEdit(this.editOrderForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改备注成功");
        this.editOrderDialog = false;
        this.detailsData.remark = this.editOrderForm.remark;
        this.getOrderList();
      });
    },
    // 导出Excel
    toExport() {
      ExportOrder(this.queryParams).then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        const blobUrl = URL.createObjectURL(blob);
        var elink = document.createElement("a");
        elink.style.display = "none";
        elink.href = blobUrl;
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      });
    },
    // 导出表格
    exportExcel() {
      /* generate workbook object from table */
      //表名
      var wb = XLSX.utils.table_to_book(document.querySelector(".orderTable"));
      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "订单.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },

    // 获取课程分类
    getSelectCourseCate() {
      selectCourseCate().then((res) => {
        this.courseTypeData = res.data;
      });
    },
    // 设置章节解锁时间
    saveChapterTime() {
      let data = {
        order_id: this.order_id,
        chapter_start: this.startTime,
        chapter_end: this.endTime,
      };
      if (data.chapter_start !== null && data.chapter_start !== "") {
        data.chapter_start = new Date(data.chapter_start).getTime() / 1000;
      } else {
        data.chapter_start = 0;
      }
      if (data.chapter_end !== null && data.chapter_end !== "") {
        data.chapter_end = new Date(data.chapter_end).getTime() / 1000;
      } else {
        data.chapter_end = 0;
      }

      OrderSetUnlock(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("设置章节解锁时间成功");
      });
    },
    // 解锁模式切换
    async radioChang(e) {
      this.unlock_mode = e;
      if (this.modeRadio !== this.choiceRadio) {
        this.modeRadio = this.choiceRadio;
      }
      const result = await this.$confirm(
        "是否要修改解锁模式?",
        "修改提示",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (result !== "confirm") return this.$message.info("已经取消修改");
      let data = {
        order_id: this.order_id,
        unlock_mode: this.unlock_mode,
      };
      OrderSwitchMode(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改解锁模式成功");
        this.modeRadio = this.unlock_mode;
      });
    },

    // 获取章节权限列表
    getOrderChapter(id) {
      this.order_id = id;
      this.orderSectionDialog = true;
      OrderChapter({ order_id: id }).then((res) => {
        this.userChapterList = res.data.lib;
        this.chapter_ids = res.data.chapter_ids;
        this.modeRadio = res.data.unlock_mode.key;
        this.choiceRadio = this.modeRadio;
        if (res.data.chapter_start == 0) {
          this.startTime = "";
        } else {
          this.startTime = res.data.chapter_start * 1000;
        }
        if (res.data.chapter_end == 0) {
          this.endTime = "";
        } else {
          this.endTime = res.data.chapter_end * 1000;
        }
      });
    },

    // 权限确认
    confirmPermissions() {
      let keys = this.$refs.tree.getCheckedKeys();
      function delArrVal(arr, val) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == val) {
            arr.splice(i, 1);
            i--;
          }
        }
        return arr;
      }
      let key = delArrVal(keys, undefined);
      let data = {
        order_id: this.order_id,
        chapterArr: key,
      };
      OrderChapterSave(data).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功!");
        }
      });
    },

    // 章节设置弹窗关闭事件
    setClosed() {
      this.getOrderList();
      this.startTime = "";
      this.endTime = "";
    },

    // 查看详情
    toViewDetails(row) {
      this.orderDetailsId = row.id;
      this.getOrderDetails(row.id);
      this.getOrderRateLearn(row.id);
      this.detailsDialog = true;
    },

    //外国人护照签约
    addForeigners(data){
      console.log(data)
      this.goods_type = data.goods_type;
      this.addForeignersDialog = true;
      this.order_id = data.id;
    },
    //外国人护照签约确认保存
    confirmSave(){
      let data = {
        id_card: this.id_card,
        client: this.client,
        is_agree: 1,
        order_id: this.order_id,
        phone: this.phone,
      };
      foreignersOrder(data).then((res) => {
        if(res.code==1){
          this.addForeignersDialog = false;
          this.$message.success(res.msg);
          this.id_card = "";
          this.client = "";
          this.phone = "";
        }
        console.log(res)
      });
    },
    addForeignersClosed(){
      this.id_card = "";
      this.client = "";
      this.phone = "";
    },
    //查看培训协议
    sel_contract(order_sn) {
      getContract({ order_sn: order_sn }).then((res) => {
        window.open(res.data.url);
      });
    },
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getOrderList();
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getOrderList();
    },
    // 获取全部订单
    getOrderList() {
      getOrder(this.queryParams).then((res) => {
        this.orderList = res.data.data;
        this.total = res.data.total;
      });
    },
    // 删除订单
    async removeOrder(i) {
      const result = await this.$confirm(
        "是否要删除该订单?",
        "删除提示",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      removeOrder(i).then((res) => {
        if (res.code !== 1) {
          this.$message.error("删除订单失败");
          return;
        }
        this.$message.success("删除订单成功");
        this.getOrderList();
      });
    },
    onSubmit() {
      this.queryParams.page = 1;
      if (this.times == null) {
        this.queryParams.start_time = "";
        this.queryParams.end_time = "";
      }
      if (this.times) {
        this.queryParams.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryParams.end_time = new Date(this.times[1]).getTime() / 1000;
      }
      this.getOrderList();
    },
    async editOrderEnable(id, enable) {
      if (enable == 4) {
        const result = await this.$confirm(
          "是否将订单改为已退款?",
          "修改提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => err);
        if (result !== "confirm") return this.$message.info("已经取消修改");
        OrderEnable({ id: id, enable: 4 }).then((res) => {
          if (res.code !== 1) {
            this.$message.error("修改状态失败!");
            return;
          }
          this.$message.success("修改成功");
          this.getOrderList();
        });
      }
      if (enable == 2) {
        const result = await this.$confirm(
          "是否将订单改为已付款?",
          "修改提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => err);
        if (result !== "confirm") return this.$message.info("已经取消修改");
        OrderEnable({ id: id, enable: 2 }).then((res) => {
          if (res.code !== 1) {
            this.$message.error("修改状态失败!");
            return;
          }
          this.$message.success("修改成功");
          this.getOrderList();
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.el-tag {
  cursor: pointer;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}
.jurisdiction {
  /* height: 700px; */
  border-top: 1px solid #ccc;

  .unlock_mode {
    margin-top: 30px;
    height: 100px;
    .unlock_mode_title {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 20px;
    }
  }
  .jurisdiction-content {
    height: 600px;
    overflow: auto;
    .title_mode {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 20px;
    }
    ::v-deep .custom-tree-node {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .node_label {
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .unlock_time {
        // margin-left: 20px;
        // float: right;
        color: #37a58c;
      }
    }
  }
  .jurisdiction-confirm {
    text-align: center;
    height: 100px;
    line-height: 100px;
    margin: auto;
    border-top: 1px solid #ccc;
    .el-button {
      width: 90px;
      height: 40px;
    }
  }
}

.remark {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.order_history {
  font-size: 15px;
  font-weight: bold;
  color: #323232;
}

.details_box {
  width: 100%;
  .details_info {
    border: 1px solid #ebeef5;
    .details_title {
      // width: 100%;
      height: 40px;
      border-bottom: 1px solid #ebeef5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      background-color: #f5f7fa;
      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 24px;
        color: #323232;
      }

      .look_contract {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #37a58c;
        cursor: pointer;
      }
    }

    .details_content {
      padding: 20px;
      display: flex;
      // align-items: center;
      .details_content_left {
        margin-right: 20px;
        img {
          width: 160px;
          height: 90px;
        }
      }
      .details_content_right {
        width: 100%;
        .item_info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
          span {
            flex: 8;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #646464;
            opacity: 1;
          }

          .edit_btn {
            cursor: pointer;
            width: 48px;
            height: 24px;
            background-color: #37a58c;
            border-radius: 2px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .learning_process_box {
    margin-top: 30px;
    .learning_process_title {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #323232;
      margin-bottom: 20px;
    }
  }
}
.footer-btn {
  display: flex;
  justify-content: space-evenly;
}
// 系统章节模式 子节点隐藏
/deep/.systemchapter .el-tree-node.is-expanded>.el-tree-node__children {
  display: none !important;
}
// 系统章节模式 箭头图标隐藏
/deep/.systemchapter .el-tree-node__expand-icon.expanded {
  display: none !important;
}
</style>
